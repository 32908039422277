import { PageProps } from "gatsby";
import React from "react";

import { BlankStaffPaper } from "../../components/pages/BlankStaffPaper";

import { dnProps } from "../../js/utils";

const BlankStaffPaperPage = (props: PageProps) => (
  <BlankStaffPaper {...{ ...dnProps, ...props }} />
);

export default BlankStaffPaperPage;
